// // body {
// //     margin: 0;
// //     padding: 0;
// //     font-family: Lexend !important;
// //     font-size: 14px !important;
// //     background-color: #f3f3f3;
// //     color: #222;
// //   }

// body {
//   overflow: overlay !important;
//   padding-right: 0px !important;
//   height: 100%;
// }

// .no-scroll {
//   overflow: hidden !important;
// }
// .add-scroll {
//    overflow: auto !important;
// }
.selectFocus:focus {
  background-color: transparent;
}

.disabled-label {
  color: rgba(0, 0, 0, 0.38) !important;
}
.no-border {
  border: none;
  color: #0000008a !important;
  font-size: 0.875rem !important;
  font-weight: 500;
  max-width: 100px;
  background-color: transparent;
  font-family: Lexend !important;
}
.no-border:focus {
  border: none;
  outline: none;
}
.no-border:focus-visible {
  border: none;
  outline: none;
}
.Mui-selected input.no-border {
  color: #fff !important;
}

.onHoverRow:hover {
  background-color: #fff8f8;
  border-radius: 10px;

  .onHover {
    color: #ff5d42;
  }
  td {
    border-bottom: solid 1px #ffc7c7;
    border-top: solid 1px #ffc7c7;
  }
  .borderleft {
    border-left: solid 1px #ffc7c7 !important;
  }
  .borderright {
    border-right: solid 1px #ffc7c7 !important;
  }
}
.onHoverRowTypeOne:hover {
  background-color: #fff8f8;
  border-radius: 10px;
  td {
    border-bottom: solid 1px #ffc7c7;
    border-top: solid 1px #ffc7c7;
  }
  .borderleft {
    border-left: solid 1px #ffc7c7 !important;
  }
  .borderright {
    border-right: solid 1px #ffc7c7 !important;
  }
}
.Mui-selected.onHoverRowTypeOne {
  background-color: #ffefef;
  border-radius: 10px;
  td {
    border-bottom: solid 1px #ffc7c7;
    border-top: solid 1px #ffc7c7;
  }
  .borderleft {
    border-left: solid 1px #ffc7c7 !important;
  }
  .borderright {
    border-right: solid 1px #ffc7c7 !important;
  }
}
.annouceingRow {
  background-color: #fffcfc;
  border-radius: 10px;
  td {
    border-bottom: solid 2px #ff5656 !important;
    border-top: solid 2px #ff5656 !important;
  }
  .borderleft {
    border-left: solid 2px #ff5656 !important;
  }
  .borderright {
    border-right: solid 2px #ff5656 !important;
  }
}
.onHoverRowTypeOne.annouceingRow:hover {
  background-color: #fff8f8;
  border-radius: 10px;
  td {
    border-bottom: solid 2px #ff5656 !important;
    border-top: solid 2px #ff5656 !important;
  }
  .borderleft {
    border-left: solid 2px #ff5656 !important;
  }
  .borderright {
    border-right: solid 2px #ff5656 !important;
  }
}
.Mui-selected.onHoverRowTypeOne.annouceingRow {
  background-color: #fff8f8;
  border-radius: 10px;
  td {
    border-bottom: solid 2px #ff5656 !important;
    border-top: solid 2px #ff5656 !important;
  }
  .borderleft {
    border-left: solid 2px #ff5656 !important;
  }
  .borderright {
    border-right: solid 2px #ff5656 !important;
  }
}
#simple-popover[data-popper-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
}
.center {
  margin: 0 auto;
  text-align: center;
}

.spacer {
  margin-top: 6rem;
  margin-bottom: 4rem;
}

/* barcode container */
.anim-box {

  position: relative;
  width: 252px;
  height: 248px;
  padding: 0px 8px 15px 8px;
  transition: transform 0.6s ease-out;
}

/* adds the 4 corners */
.anim-box:before,
.anim-box:after,
.anim-box > :first-child:before,
.anim-box > :first-child:after {
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: #000;
  border-style: solid;
  content: ' ';
}

/* top left corner */
.anim-box:before {
  top: 0;
  left: 0;
  border-width: 1px 0 0 1px;
}

/* top right corner */
.anim-box:after {
  top: 0;
  right: 0;
  border-width: 1px 1px 0 0;
}

/* bottom right corner */
.anim-box > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 1px 1px 0;
}

/* bottom left corner */
.anim-box > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 1px 1px;
}

/* barcode bars */
.anim-item {
  display: inline-block;
  background-color: #000;
  height: 200px;
}

.anim-item-sm {
  width: 2px;
  margin-right: 3px;
}

.anim-item-md {
  width: 3px;
  margin-right: 2px;
}

.anim-item-lg {
  width: 13px;
  margin-right: 5px;
}

/* animated laser beam */
.scanner {
  z-index: 2;
  width: 100%;
  height: 15px;
  border-radius: 2px;
  background-color: #f00;
  opacity: 0.6;
  position: relative;
  box-shadow: none;
  top: 50%;
  animation-name: scan;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes scan {
  0% {
    top: 50%;
  }
  25% {
    top: 5px;
  }
  75% {
    top: 98%;
  }
}
.da-code {

  position: fixed;
  font-size: 8px;
  height: 1em;
  width: 1em;
  margin: 10px 15px 10px 20px;
  float: left;
  box-shadow: /* row 1 */ 8em 0 #000, 10em 0 #000, 11em 0 #000, 12em 0 #000, 14em 0 #000,
    16em 0 #000, /* row 2 */ 12em 1em #000, 13em 1em #000, 14em 1em #000, 16em 1em #000,
    /* row 3 */ 8em 2em #000, 9em 2em #000, 10em 2em #000, 13em 2em #000, 14em 2em #000,
    16em 2em #000, /* row 4 */ 9em 3em #000, 13em 3em #000, 14em 3em #000, 16em 3em #000,
    /* row 5 */ 10em 4em #000, 11em 4em #000, 12em 4em #000, 13em 4em #000, 14em 4em #000,
    /* row 6 */ 8em 5em #000, 10em 5em #000, 12em 5em #000, 13em 5em #000, 16em 5em #000,
    /* row 7 */ 8em 6em #000, 10em 6em #000, 12em 6em #000, 14em 6em #000, 16em 6em #000,
    /* row 8 */ 9em 7em #000, 14em 7em #000, 16em 7em #000, /* row 9 */ 0em 8em #000, 2em 8em #000,
    6em 8em #000, 7em 8em #000, 10em 8em #000, 12em 8em #000, 15em 8em #000, 16em 8em #000,
    19em 8em #000, 22em 8em #000, 24em 8em #000, /* row 10 */ 0em 9em #000, 1em 9em #000,
    3em 9em #000, 5em 9em #000, 8em 9em #000, 10em 9em #000, 12em 9em #000, 13em 9em #000,
    15em 9em #000, 16em 9em #000, 18em 9em #000, 19em 9em #000, 21em 9em #000, 23em 9em #000,
    24em 9em #000, /* row 11 */ 5em 10em #000, 6em 10em #000, 7em 10em #000, 10em 10em #000,
    11em 10em #000, 15em 10em #000, 16em 10em #000, 18em 10em #000, 19em 10em #000, 20em 10em #000,
    21em 10em #000, 22em 10em #000, 24em 10em #000, /* row 12 */ 0em 11em #000, 1em 11em #000,
    2em 11em #000, 3em 11em #000, 4em 11em #000, 5em 11em #000, 8em 11em #000, 9em 11em #000,
    12em 11em #000, 14em 11em #000, 16em 11em #000, 18em 11em #000, 19em 11em #000, 21em 11em #000,
    /* row 13 */ 3em 12em #000, 4em 12em #000, 6em 12em #000, 8em 12em #000, 9em 12em #000,
    10em 12em #000, 11em 12em #000, 15em 12em #000, 16em 12em #000, 18em 12em #000, 24em 12em #000,
    /* row 14 */ 3em 13em #000, 9em 13em #000, 10em 13em #000, 13em 13em #000, 15em 13em #000,
    16em 13em #000, 17em 13em #000, 18em 13em #000, 19em 13em #000, 23em 13em #000, 24em 13em #000,
    /* row 15 */ 0em 14em #000, 1em 14em #000, 2em 14em #000, 4em 14em #000, 6em 14em #000,
    8em 14em #000, 9em 14em #000, 11em 14em #000, 13em 14em #000, 15em 14em #000, 17em 14em #000,
    18em 14em #000, 21em 14em #000, 22em 14em #000, 24em 14em #000, /* row 16 */ 3em 15em #000,
    5em 15em #000, 9em 15em #000, 14em 15em #000, 15em 15em #000, 16em 15em #000, 17em 15em #000,
    19em 15em #000, /* row 17 */ 0em 16em #000, 1em 16em #000, 6em 16em #000, 9em 16em #000,
    11em 16em #000, 12em 16em #000, 14em 16em #000, 16em 16em #000, 17em 16em #000, 18em 16em #000,
    19em 16em #000, 20em 16em #000, 23em 16em #000, /* row 18 */ 8em 17em #000, 9em 17em #000,
    10em 17em #000, 12em 17em #000, 13em 17em #000, 16em 17em #000, 20em 17em #000, 22em 17em #000,
    24em 17em #000, /* row 19 */ 8em 18em #000, 9em 18em #000, 12em 18em #000, 16em 18em #000,
    18em 18em #000, 20em 18em #000, 21em 18em #000, 24em 18em #000, /* row 20 */ 9em 19em #000,
    10em 19em #000, 11em 19em #000, 12em 19em #000, 14em 19em #000, 15em 19em #000, 16em 19em #000,
    20em 19em #000, 24em 19em #000, /* row 21 */ 13em 20em #000, 14em 20em #000, 15em 20em #000,
    16em 20em #000, 17em 20em #000, 18em 20em #000, 19em 20em #000, 20em 20em #000, 21em 20em #000,
    24em 20em #000, /* row 22 */ 9em 21em #000, 10em 21em #000, 13em 21em #000, 15em 21em #000,
    16em 21em #000, 17em 21em #000, 19em 21em #000, 20em 21em #000, 21em 21em #000, 22em 21em #000,
    23em 21em #000, /* row 23 */ 8em 22em #000, 10em 22em #000, 11em 22em #000, 15em 22em #000,
    16em 22em #000, 20em 22em #000, 23em 22em #000, 24em 22em #000, /* row 24 */ 13em 23em #000,
    16em 23em #000, 19em 23em #000, 20em 23em #000, /* row 25 */ 8em 24em #000, 10em 24em #000,
    11em 24em #000, 12em 24em #000, 13em 24em #000, 16em 24em #000, 17em 24em #000, 21em 24em #000,
    24em 24em #000;
}
.da-code:before {
  content: '';
  width: 3em;
  height: 3em;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  margin: 2em;

  box-shadow: 0 0 0 1em white, 0 0 0 2em #000, 18em 0 0 #000, 18em 0 0 1em white, 18em 0 0 2em #000,
    0 18em 0 #000, 0 18em 0 1em white, 0 18em 0 2em #000;
}

/* full screen google map search suggestions dropdown */
.fullscreen-pac-container {
  z-index: 2547483647 !important;
  top: 70px !important;
}
@keyframes popIn {
  from {
    transform: scale(0.9);
    opacity: 0.8;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.activeCard {
  animation: popIn 2s ease forwards;
}

/* hide default password eye icon in edge browser */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.carousel-container {
  min-height: 40vh;
  @media screen and (min-width: 2000px) {
    min-height: 30vh;
  }
   @media screen and (min-width: 3000px) {
    min-height: 20vh;
  }
}
.falling {
  transform: translateY(-7vh);
  animation: fall 1.5s ease-out forwards;
}
.falling3 {
  opacity: 0;
  transform: translateY(-1vh);
  animation: fall 1.7s ease-out forwards;
}
@keyframes fall {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fallingFacility {
  transform: translateY(-5vh);
  animation: fallFacility 1.5s ease-out forwards;
}
.fallingFacility1 {
  opacity: 0;
  animation: fallFacility 1.8s ease-out forwards;
}
@keyframes fallFacility {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.popIn{
  animation: popupShow 1.5s ease-out forwards;
}
.popIn1{
  animation: popupShow 1s ease-out forwards;
}
@keyframes popupShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fall2 {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.popIn2{
  transform: translateY(-2vh);
  animation: fall2 1.2s ease-out forwards;
}
.popIn3{
  transform: translateY(-2vh);
  animation: fall2 1.4s ease-out forwards;
}
.popIn4{
  transform: translateY(-2vh);
  animation: fall2 1.6s ease-out forwards;
}
.popIn5{
  transform: translateY(-2vh);
  animation: fall2 1.8s ease-out forwards;
}
.popIn6{
  transform: translateY(-2vh);
  animation: fall2 1.10s ease-out forwards;
}
.popIn7{
  transform: translateY(-2vh);
  animation: fall2 1.12s ease-out forwards;
}