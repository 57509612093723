.react-tel-input {
  .flag-dropdown {
    &.open{
      border-right: 1px solid #aeaeae;
    bottom: 12px;
    top: 12px;
    }
    border-right: 1px solid #aeaeae;
    bottom: 8px;
    top:8px
  }
  .form-control {
    border-radius: 10px;
    border: 1px solid #aeaeae;
    font-family: Lexend !important; 
    font-size: 18px !important;
    padding: 13px 13px 13px 58px !important;
    &:focus {
      border-color: black;
      box-shadow: none;
    }
    &:disabled {
      color: #aeaeae;
      border-color: #aeaeae;    
    font-size: 14px !important;

    }
 

    &.invalid-number {
      border: 1px solid #ff5d42;
      &:focus {
        border-color: 1px solid #ff5d42;
        box-shadow: none;
      }
      }
  }
  .special-label{
    color: #818181;
    left: 10px;
    font-size: .66rem !important;
    font-family: Lexend !important; 
    font-weight: 400;
    line-height: 1.4375em !important;
  }

  &:focus-within {
    ::placeholder {
      opacity: 0;
    }
    .special-label {
      display: block;
      color: black;
      // animation: 0.3s moveLeftIn ease-out;
    }
  }
}

.error {
  .special-label{
    color: #818181;
    font-weight: 500;
  }
}

